<template>
  <div>
    <b-card>
      <AgGrid
        ref="BookingArchiveGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      />
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/Booking/BookingViewBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  components: {
    AgGrid,
  },
  data() {
    return {
      BookingId: '',
      aggrid: [],
      CustomerOptions: [],
      BKDetail: {
        CustomerId: '',
        BookingNumber: '',
      },
      Permission: {},
      Role: '',
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.aggrid = this.InitGridInstance('Booking.Archive')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'bookingId',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      {
        headerName: 'Status',
        field: 'status',
        minWidth: 135,
        filter: 'agSetColumnFilter',
        // eslint-disable-next-line no-nested-ternary
        cellRenderer: params => (params.value === 'Approved' || params.value === 'Approved/Confirmed' ? `<span style="color:green">${params.value}</span>` : params.value === 'Pending Review' || params.value === 'Approved/Pending' ? `<span style="color:coral">${params.value}</span>` : params.value === 'Denied' ? `<span style="color:red">${params.value}</span>` : params.value === 'Attached' ? `<span style="color:purple">${params.value}</span>` : params.value === 'Cancelled' ? `<span style="color:red">${params.value}</span>` : `${params.value}`),
      },
      {
        headerName: 'Customer', field: 'customerName', minWidth: 125, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'HBL/Booking #', field: 'bookingNumber', minWidth: 155, enableRowGroup: false, enablePivot: false,
      },
      {
        headerName: 'Customer Reference', field: 'customerReference', minWidth: 155, enableRowGroup: false, enablePivot: false,
      },
      {
        headerName: 'Mode', field: 'bookingMode', minWidth: 75, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Service Type', field: 'bookingType', minWidth: 75, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Service Mode', field: 'serviceType', minWidth: 85, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Consingee', field: 'consigneeName', minWidth: 85, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Shipper', field: 'sellerName', minWidth: 85, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'PO Number',
        field: 'poNumber',
        maxWidth: 195,
        enableRowGroup: false,
        enablePivot: false,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: 'Booking Date',
        field: 'receivedDate',
        maxWidth: 150,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Approval Date',
        field: 'approvedDate',
        maxWidth: 150,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Cargo Ready',
        field: 'cargoReadyDate',
        maxWidth: 150,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: '# Containers', field: 'totalContainers', minWidth: 100,
      },
      {
        headerName: 'Delivery Location', field: 'deliveryLocation', minWidth: 80,
      },
      {
        headerName: 'Final Destination', field: 'finalDest', minWidth: 80,
      },
      {
        headerName: 'CBM / Weight / Pallet',
        field: 'cbm',
        minWidth: 100,
        enableRowGroup: false,
        enablePivot: false,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          innerRenderer: params => `${params.value || '0'} / ${params.data.weight || '0'} / ${params.data.pallet || '0'}`,
        },
      },
      {
        headerName: 'Edit Log',
        marryChildren: true,
        children: [
          {
            headerName: 'Updated By', field: 'updatedBy', maxWidth: 134, minWidth: 100,
          },
          {
            headerName: 'Updated On',
            field: 'updatedOn',
            maxWidth: 158,
            minWidth: 100,
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            enableRowGroup: false,
            enablePivot: false,
          },
          {
            headerName: 'Added By', columnGroupShow: 'open', field: 'createdBy', maxWidth: 134, minWidth: 100,
          },
          {
            headerName: 'Added On',
            columnGroupShow: 'open',
            field: 'createdOn',
            maxWidth: 158,
            minWidth: 100,
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
            enableRowGroup: false,
            enablePivot: false,
          },
        ],
      },
    ]

    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.BookingArchiveGrid.saveState('Booking.Archive') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.BookingArchiveGrid.saveState('Booking.Archive') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.BookingArchiveGrid.saveState('Booking.Archive') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.BookingArchiveGrid.saveState('Booking.Archive') }

    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(BookingId) {
      this.BookingId = BookingId
      this.$router.push(`/booking/detail/${BookingId}`)
    },
    DoubleClickOpenPopup(params) {
      this.BookingId = params.data.bookingId
      this.$router.push(`/booking/detail/${params.data.bookingId}`)
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadBookingList()])
        .then(() => {
          // Get user role
          this.Role = this.GetUserRole()
          // Get permission
          this.Permission = this.GetUserPermission('Booking')
          this.LoadingEnd()
        })
    },
    LoadBookingList() {
      axios.get('/booking/archived')
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.BookingArchiveGrid.ResetColumns()
        })
    },
  },
}
</script>
